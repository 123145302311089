<template>
  <div class="slider h-100">
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i" class="slides" @hovered="startSlide">
        <img :src="currentImg" class="slides" />
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "SlideShow",
  props: {
      categories:Array,
  },
 data() {
   return {
     images: [
       "/img/1.jpg",
     ],
     obj:new Object(),
     timer: null,
     currentIndex: 0
   };
 },
 methods: {
   startSlide: function() {
     this.timer = this.next();
   },
   dataSync: async function(){
     await this.categories.forEach(this.setData);
   },
   setData:function(index,item){
    this.images[index.id] = index.sliderImg;
   },
   next: function() {
     this.currentIndex += 1;
   },
   prev: function() {
     this.currentIndex -= 1;
   },
   indexing(index){
     //console.log(this.obj);
     return this.currentIndex = index;
   }
 },
 computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
}
</script>

<style lang="css" scoped>
.slider{
  width: 100%;
  height: 90%;
  z-index: 100;
  position: relative;
  background-color: #fff;
}
.slides{
  width: 100%;
  height: 100% !important;
  overflow-y: hidden;
  filter: grayscale(70%);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: relative;
  width:100%;
  transform: translateY(-100%);
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  height: 0%;
  transform: translateY(100%);
  opacity: 1;
}
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.9);
}
</style>
